import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Experience"
				body={
					<div className="works-body">
						<div className="work">
							<div className="work-header">
								<div className="work-title">Brainybucks</div>
								<div className="work-subtitle">
									Project Manager and Software Engineer
								</div>
								<div className="work-duration">
									Feb 2023 - Jul 2023
								</div>
							</div>
							<div className="work-tech-stack">
								Tech stack: MERN (MongoDB, Express, React,
								Node.js), JavaScript, HTML, CSS
							</div>
							<ul className="work-details">
								<li>
									Collaborated with a team of five students to
									successfully launch a responsive website.
								</li>
								<li>
									Ensured alignment with stakeholder goals.
								</li>
								<li>Managed project scope and delivery.</li>
								<li>
									Translated complex Figma designs into
									precise code, ensuring seamless integration.
								</li>
								<li>
									Integrated custom subscription payment model
									using Stripe API, ensuring secure
									transactions.
								</li>
								<li>
									Designed an intuitive front end for the web
									application, prioritizing user experience.
								</li>
							</ul>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
