const INFO = {
	main: {
		title: "Portfolio - Vibolroath Leav",
		name: "Vibolroath Leav",
		email: "vibolroath.leav@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/Vibolroath",
		linkedin: "https://www.linkedin.com/in/vibolroath-leav-567996220/",
	},

	homepage: {
		title: "Hello, I'm Vibolroath",
		description:
			"I am a software engineer and web developer from Australia with a passion for building websites that not only look great but work seamlessly. There's something special about taking an idea and turning it into a fully functional, user-friendly experience through clean code and careful design. Every project is an opportunity to grow, and I’m always excited to take on new challenges that help me become a better developer.",
	},

	about: {
		title: "I'm Vibolroath Leav, though most people simply call me Jay.",
		description:
			"A dedicated Software Engineer and Web Developer. With a robust background in Information Technology, I specialize in Web Development, bringing extensive experience in creating dynamic and user-focused designs.",
		description2:
			"My previous experience at Brainybucks has provided me with deep insights into project management, collaborative problem-solving, and the critical integration of design and development. From building website architectures from the ground up to leading teams toward successful project completions.",
		description3:
			"Beyond my professional life, I am passionate about exploring new technologies, experimenting with innovative ideas, and contributing to the vibrant tech community. I believe in continuous learning and staying up-to-date with industry trends to remain at the cutting edge of innovation.",
		description4:
			" I am always eager to connect with fellow professionals, explore exciting opportunities, and collaborate on projects that challenge the status quo in the digital space. Let's connect and see how we can work together to create exceptional digital experiences!",
		title2: "My Educational Background",
	},

	aboutme: {
		title: "What I do?",
	},

	projects: [
		{
			title: "Brainybucks",
			description: "Website Projects",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 2",
			description: "Blank.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 3",
			description: "Blank.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 4",
			description: "Blank.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Project 5",
			description: "Blank.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com",
		},
	],
};

export default INFO;
