import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Player } from "@lottiefiles/react-lottie-player";

import Card2 from "../components/common/card2";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import "./styles/about.css";

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "about");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`About | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="about" />
				<div className="content-wrapper">
					<div className="about-logo-container">
						<div className="about-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="about-container">
						<div className="about-main">
							<div className="about-right-side">
								<div className="title about-title">
									{INFO.about.title}
								</div>

								<div className="subtitle about-subtitle">
									<p>{INFO.about.description}</p>
									<p>{INFO.about.description2}</p>
									<p>{INFO.about.description3}</p>
									<p>{INFO.about.description4}</p>
								</div>

								<div className="title about-title2">
									{INFO.about.title2}
								</div>

								<div className="subtitle about-subtitle">
									<p>{INFO.about.description5}</p>
								</div>

								<div className="works2">
									<Card2
										body={
											<div className="works-body">
												<div className="work">
													<div className="work-header">
														<div className="work-title">
															Bachelor of
															Information
															Technology
														</div>
														<div className="work-subtitle">
															Macquarie University
														</div>
														<div className="work-duration">
															2021 - Jul 2023
														</div>
													</div>
												</div>
											</div>
										}
										body2={
											<div className="works-body">
												<div className="work">
													<div className="work-header">
														<div className="work-title">
															Diploma of
															Information
															Technology
														</div>
														<div className="work-subtitle">
															Macquarie University
														</div>
														<div className="work-duration">
															2020 - Jul 2021
														</div>
													</div>
												</div>
											</div>
										}
									/>
								</div>
							</div>

							<div className="about-left-side">
								<div className="about-image-container">
									<div className="about-image-wrapper">
										<Player
											src="https://lottie.host/3d817ceb-f50e-43c1-97dc-4f977ceabacd/JQ9UQGGbfk.json"
											className="player"
											loop
											autoplay
										/>
									</div>
								</div>

								<div className="about-socials">
									<Socials />
								</div>
							</div>
						</div>
						<div className="about-socials-mobile">
							<Socials />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default About;
