import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Player } from "@lottiefiles/react-lottie-player";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Works from "../components/homepage/works";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";
import myArticles from "../data/articles";

import "./styles/homepage.css";

const Homepage = () => {
	const [stayLogo, setStayLogo] = useState(false);
	const [logoSize, setLogoSize] = useState(100);
	const [oldLogoSize, setOldLogoSize] = useState(100);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			let scroll = Math.round(window.pageYOffset, 2);

			let newLogoSize = 100 - (scroll * 4) / 10;

			if (newLogoSize < oldLogoSize) {
				if (newLogoSize > 40) {
					setLogoSize(newLogoSize);
					setOldLogoSize(newLogoSize);
					setStayLogo(false);
				} else {
					setStayLogo(true);
				}
			} else {
				setLogoSize(newLogoSize);
				setStayLogo(false);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [logoSize, oldLogoSize]);

	const currentSEO = SEO.find((item) => item.page === "home");

	const logoStyle = {
		display: "flex",
		position: stayLogo ? "fixed" : "relative",
		top: stayLogo ? "3vh" : "auto",
		zIndex: 999,
		border: stayLogo ? "1px solid white" : "none",
		borderRadius: stayLogo ? "50%" : "none",
		boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="home" />
				<div className="content-wrapper">
					<div className="homepage-logo-container">
						<div style={logoStyle}>
							<Logo width={logoSize} link={false} />
						</div>
					</div>

					<div className="homepage-container">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side">
								<div className="title homepage-title">
									{INFO.homepage.title}
								</div>

								<div className="subtitle homepage-subtitle">
									{INFO.homepage.description}
								</div>
							</div>

							<div className="homepage-first-area-right-side">
								<div className="homepage-image-container">
									<div className="homepage-image-wrapper">
										<Player
											src="https://lottie.host/8f1749ff-0d2d-4620-a564-c087a717007a/RKco7LFAMt.json"
											className="player"
											loop
											autoplay
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="homepage-socials">
							<a
								href={INFO.socials.github}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faGithub}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={`mailto:${INFO.main.email}`}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faMailBulk}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.linkedin}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faLinkedin}
									className="homepage-social-icon"
								/>
							</a>
						</div>

						<div className="title homepage-projects">
							{INFO.aboutme.title}
						</div>

						<div className="subtitle homepage-projects">
							<div className="experience-container">
								<div className="experience-item">
									<strong>HTML5</strong>
									<p>
										Extensive experience with HTML5 for
										building the structure and layout of web
										pages.
									</p>
								</div>

								<div className="experience-item">
									<strong>CSS</strong>
									<p>
										Proficient in CSS, utilizing it for
										designing responsive and visually
										appealing web pages.
									</p>
								</div>

								<div className="experience-item">
									<strong>JavaScript</strong>
									<p>
										Skilled in building dynamic web
										applications using JavaScript, focusing
										on both client-side and server-side
										functionality.
									</p>
								</div>

								<div className="experience-item">
									<strong>React</strong>
									<p>
										Hands-on experience with React.js for
										developing interactive and user-friendly
										interfaces.
									</p>
								</div>

								<div className="experience-item">
									<strong>Node.js</strong>
									<p>
										Experience working with Node.js to build
										scalable and efficient server-side
										applications.
									</p>
								</div>

								<div className="experience-item">
									<strong>Express.js</strong>
									<p>
										Utilized Express.js for developing
										robust back-end applications and RESTful
										APIs.
									</p>
								</div>

								<div className="experience-item">
									<strong>MongoDB</strong>
									<p>
										Experienced with MongoDB for database
										design, querying, and optimizing storage
										and retrieval of data in web
										applications.
									</p>
								</div>

								<div className="experience-item">
									<strong>Git</strong>
									<p>
										Proficient in version control using Git,
										managing source code effectively for
										both solo and team-based projects.
									</p>
								</div>

								<div className="experience-item">
									<strong>GitHub</strong>
									<p>
										Skilled in using GitHub for repository
										management, code collaboration, and
										version tracking.
									</p>
								</div>

								<div className="experience-item">
									<strong>Jira</strong>
									<p>
										Experienced with Jira for task tracking
										and project management in both academic
										and professional projects.
									</p>
								</div>
							</div>
						</div>

						<div className="homepage-after-title">
							<div className="homepage-articles">
								{myArticles.map((article, index) => (
									<div
										className="homepage-article"
										key={(index + 1).toString()}
									></div>
								))}
							</div>

							<div className="homepage-works">
								<Works />
							</div>
						</div>

						<div className="page-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
