import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./styles/card2.css";

const Card2 = (props) => {
	const { icon, title, body, body2 } = props;
	return (
		<div className="card2">
			<div className="card2-container">
				<div className="card2-header">
					<div className="card2-icon">
						<FontAwesomeIcon icon={icon} />
					</div>
					<div className="card2-title">{title}</div>
				</div>
				<div className="card2-body">
					<div className="card2-text">{body}</div>
				</div>
				<div className="card2-body2">
					<div className="card2-text">{body2}</div>
				</div>
			</div>
		</div>
	);
};

export default Card2;
